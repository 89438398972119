import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { Routes, Route, Router, BrowserRouter } from 'react-router-dom';
import { RunCodeProvider } from './pages/reducer/runCodeReducer';
import JsonPage from './pages/json';
import Dashboard from './pages/dashboard';
import Premium from './pages/premium';
import 'react-confirm-alert/src/react-confirm-alert.css';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <RunCodeProvider>
        <BrowserRouter>
          <Routes>
            <Route path="" element={<App />} />
            <Route path="/:_id" element={<App />} />
            {/* <Route path="/json/api/:_id" element={<JsonPage />} /> */}
            <Route path='/dashboard' element={<Dashboard />}/>
            <Route path='/premium' element={<Premium />}/>
            <Route path='/:id/dashboard' element={<Dashboard />}/>

            <Route path='/dashboard/:id' element={<Dashboard />}/>
          </Routes>
        </BrowserRouter>
      </RunCodeProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
