import React, { useEffect, useState } from "react";
import Navbar from "../navbar";
import { Box, Button, HStack, IconButton, VStack } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { collection, getDocs, deleteDoc, doc, Timestamp } from "firebase/firestore";
import { db, auth } from '../../firebase';
import { useNavigate } from "react-router-dom";
import { useRunCode } from "../reducer/runCodeReducer";
import { useParams } from 'react-router-dom';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { confirmAlert } from "react-confirm-alert";

interface CodeData {
  // Define the structure of your data here
  // For example: auth: string, content: string, etc.
  auth: string,
  code: string,
  id: string,
  send_at: Timestamp,
  edit_at: string,
}
function TimeToString(send_at: Timestamp): string {
  const date = new Date(send_at.toMillis());
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are 0-indexed
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format day and month to have leading zero if needed
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  const formattedDateTime = `${hours}:${minutes} ${formattedDay}/${formattedMonth}/${year}`;
  return formattedDateTime;
}

function Dashboard() {
  const { id } = useParams();
  const Navigate = useNavigate();
  const [code, setCode] = useState<{ [key: string]: CodeData }>({})
  const { state, dispatch } = useRunCode();
  const [reload, setReload] = useState(false)
  // console.log(id);


  // if () {
  // Navigate("/Dashboard")
  // }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "pubCode"));
        const codeData: { [key: string]: CodeData } = {};
        querySnapshot.docs.forEach(doc => {
          const data = doc.data() as CodeData;
          codeData[doc.id] = { ...data, id: doc.id };
        });
        // if (codeData[key].auth == state.user.email) {
        const userAuthEmail = state.user.email;

        const filteredCodeData: { [key: string]: CodeData } = {};
        Object.keys(codeData).forEach(key => {
          if (codeData[key].auth === userAuthEmail) {
            filteredCodeData[key] = codeData[key];
          }
        });

        setCode(prevCode => ({ ...prevCode, ...filteredCodeData }));
        // }
        // Merge the fetched data with the existing state

      } catch (error) {
        console.error("Error fetching data from Firestore:", error);
      }
    };
    fetchData();
  }, [reload]);

  const editCode = (id: string) => {
    // const history = useHistory();
    Navigate("/" + id);
  }

  const deleteCode = async (id: string) => {
    await deleteDoc(doc(db, "pubCode", id));
  }
  if (state.user == null) return <>
    <Navbar />
    {/* {dispatch({ type: "noti", title: "Lỗi", content: "Vui lòng login hoặc đăng kí", icon: "error" })} */}
    <Box
      // bg="#dddbdb"
      w="68%"
      p={7}
      color="black"
      borderRadius="12px"
      margin="0px auto"
      marginTop="center"
    >
      <Alert
        status='error'
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'
      >
        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          Lỗi !
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          Vui lòng đăng nhập hoặc đăng kí
        </AlertDescription>
      </Alert>
    </Box>
  </>
  return (
    <>
      <Navbar />

      {Object.keys(code).map((key) => (
        <div key={key}>
          <Box
            bg="#dddbdb"
            w="68%"
            p={3}
            color="black"
            borderRadius="12px"
            margin="0px auto"
            marginTop="15px"
            position={"relative"}
          >
            <p onClick={() => {
              editCode(code[key].id)
            }}>
              {code[key].code.split(' ').slice(0, 30).join(' ') + ' ... '}
            </p>
            <HStack width={"100%"}>
            <Box width={"50%"} textAlign={"start"} >
             <b>Tạo lúc: </b>{TimeToString(code[key].send_at)}
             </Box>
              <Box width={"50%"} textAlign={"end"} >
              <IconButton
                colorScheme='red'
                aria-label='Search database'
                icon={<DeleteIcon />}

                onClick={() => {
                  confirmAlert({
                    title: 'Bạn có chắc chắc muốn xóa?',
                    message: 'Dữ liệu sẽ không thể nào được phục hồi nếu xóa',
                    buttons: [
                      {
                        label: 'Xác nhận',
                        onClick: () => {
                          deleteCode(code[key].id)
                          setCode({})
                          setReload(!reload)
                        }
                      },
                      {
                        label: 'Hủy',
                      }
                    ]
                  });
                }}

              />


            </Box>
            </HStack>
            
          </Box>
        </div>
      ))}
    </>
  );
}

export default Dashboard;
