import React, { useEffect, useReducer, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import LuaCodeEditor from './pages/home';
import Navbar from './pages/navbar';
import { RunCodeProvider, useRunCode } from './pages/reducer/runCodeReducer';
import { useParams } from 'react-router-dom';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Box } from '@chakra-ui/react';


function App() {
  const { _id } = useParams();
  const { state, dispatch } = useRunCode();
  const { showConsole, codeData, share, showNoti } = state;
  useEffect(()=>{
    if (showNoti){
      setTimeout(() => {

        dispatch({type:"disnoti"})

    }, 5000);
    }
  },[showNoti])
  return (
    <>
      <Navbar _id={_id}/>
      <LuaCodeEditor _id={_id} />
      {state.showNoti && (<Box position={"fixed"} width={"full"} top={"50%"}><Alert
        status={state.icon}
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'>

        <AlertIcon boxSize='40px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          {state.title}
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          {state.content}
        </AlertDescription>
      </Alert>
      </Box>)}
</>
    


  );
}

export default App;
