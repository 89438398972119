
import { createContext, useContext, useReducer } from "react"

// Định nghĩa các hành động
type RunCodeAction = {type:"reset"}|{ type: "noti", title: String, content: String, icon: any } | { type: "disnoti" } | { type: "share", status: boolean } | { type: "logout" } | { type: "open" } | { type: "close" } | { type: "updateCode", code: string } | { type: "login", user: any };

// Định nghĩa kiểu trạng thái
interface RunCodeState {
    showConsole: boolean;
    codeData: string;
    user: any,
    share: boolean,
    title: String,
    content: String,
    icon: any,
    showNoti: boolean
}

const initialState: RunCodeState = {
    showConsole: false,
    codeData: "",
    user: null,
    share: false,
    title: "Lỗi",
    content: "Không xác định",
    icon: "error",
    showNoti: false
}
const RunCodeContext = createContext<{ state: RunCodeState; dispatch: React.Dispatch<RunCodeAction> } | undefined>(undefined);

function runCode(state: RunCodeState, action: RunCodeAction): RunCodeState {
    switch (action.type) {
        case "open":
            return { ...state, showConsole: true };
        case "close":
            return { ...state, showConsole: false };
        case "updateCode":
            return { ...state, codeData: action.code };
        case "login":
            return { ...state, user: action.user };
        case "logout":
            return { ...state, user: null };
        case "share":
            return { ...state, share: action.status };
        case "noti":
            return { ...state, title: action.title, content: action.content, icon: action.icon, showNoti: true };
        case "disnoti":
            return { ...state, showNoti: false };
        case "reset":
            return {...state,codeData:""}
        default:
            throw new Error();
    }
}
export function RunCodeProvider({ children }: { children: React.ReactNode }) {
    const [state, dispatch] = useReducer(runCode, initialState);
    return (
        <RunCodeContext.Provider value={{ state, dispatch }}>
            {children}
        </RunCodeContext.Provider>
    );
}
export function useRunCode() {
    const context = useContext(RunCodeContext);
    if (!context) {
        throw new Error('useRunCode must be used within a RunCodeProvider');
    }
    return context;
}