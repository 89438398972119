'use client'
import firebase from 'firebase/app';
import 'firebase/auth';
import {
    Box,
    Flex,
    Avatar,
    HStack,
    Text,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    useColorModeValue,
    Stack,
    Center,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    useColorMode,
    ColorModeContext,
    WrapItem,
    Wrap,
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon, AddIcon, UnlockIcon, LockIcon, RepeatIcon, DeleteIcon, CopyIcon, MoonIcon, SunIcon } from '@chakra-ui/icons'


import { FcGoogle, FcKey } from 'react-icons/fc'
import { useRunCode } from '../reducer/runCodeReducer'
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut } from 'firebase/auth';
import { auth, db } from '../../firebase';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { doc, getDoc } from 'firebase/firestore';

interface Props {
    to: string,
    children: React.ReactNode
}

const Links = ['Dashboard', 'Premium']
var copyToClipboard = (text: string) => {
    var textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
}
const NavLink1 = (props: Props) => {
    const { to, children } = props
    return (
        // <Box
        //     // as="a"
        //     px={2}
        //     py={1}
        //     rounded={'md'}
        //     _hover={{
        //         textDecoration: 'none',
        //         bg: useColorModeValue('gray.200', 'gray.700'),
        //     }}
        //     // href={'#'}
        // >
        //     <NavLink to={children} >
        //     {children}
        //     </NavLink>
        // </Box>
        <Box px={2} py={1} rounded={'md'} _hover={{ textDecoration: 'none', bg: useColorModeValue('gray.200', 'gray.700') }}>
            <NavLink to={to}>{children}</NavLink>
        </Box>
    )
}

export default function Navbar({ _id }: any) {
    const [saveStatus, setSaveStatus] = useState("Chia sẻ")
    const isOwn = async () => {
        try {
            const userRef = doc(db, 'pubCode', _id);
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                if (userDoc.data().auth == state.user.email) {
                    return true
                }
            }
            return false
        } catch (error) {
            return false
        }

    }
    async function check() {
        try {
            if (_id) {
                if (await isOwn()) {
                    setSaveStatus("Tự động lưu")
                }
            }
        } catch (error) {
            setSaveStatus("Chia sẻ")
        }

    }
    useEffect(() => {
        check()
    }, [saveStatus])
    const Navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { colorMode, toggleColorMode } = useColorMode()
    const { state, dispatch } = useRunCode();
    const handleLogout = () => {
        signOut(auth).then((p) => {
            dispatch({ type: "logout" })
        })

    }
    const signIn = () => {
        const provider = new GoogleAuthProvider()
        signInWithPopup(auth, provider).then((userCredential) => {
            const user = userCredential.user;
            dispatch({ type: "login", user: user })
        })
            .catch((error) => {
            });
    };
    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                if (state.user === null) {
                    dispatch({ type: "login", user: user })
                }

            } else {
                if (state.user != null) {
                    dispatch({ type: "logout" })
                }
            }
        });

    }, [])

    return (
        <>
            <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4} height={"10%"}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'} height="10vh">
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={8} alignItems={'center'}>
                        <Box><b onClick={() => {
                            if (_id == undefined) {
                                if (state.codeData.length <= 1) {
                                    dispatch({ type: "reset" })
                                    Navigate("../")
                                } else {
                                    confirmAlert({
                                        title: 'Về trang chủ?',
                                        message: 'Toàn bộ code hiện tại chưa được lưu',
                                        buttons: [
                                            {
                                                label: 'Xác nhận',
                                                onClick: async () => {
                                                    dispatch({ type: "reset" })
                                                    Navigate("../")

                                                }

                                            },
                                            {
                                                label: 'Hủy',
                                                onClick: async () => {
                                                    Navigate("../")

                                                }
                                            }
                                        ]
                                    });

                                }

                            } else {
                                dispatch({ type: "reset" })
                                Navigate("../")
                            }

                        }}>LuaEditor</b></Box>
                        <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                            {Links.map((link) => (
                                <NavLink1 to={"../" + link} key={link}>{link}</NavLink1>
                            ))}
                            {_id ? (<FormControl>
                                <InputGroup >
                                    <Input
                                        pr="3rem"
                                        type={'text'}
                                        value={window.location.host + "/" + _id}
                                        color={colorMode === 'light' ? "red" : "yellow"}
                                    />
                                    <InputRightElement >
                                        <IconButton
                                            aria-label='Search database'
                                            icon={<CopyIcon />}
                                            onClick={() => {
                                                copyToClipboard(window.location.host + "/" + _id)
                                            }}
                                        />
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>) : null}
                            <Wrap>
  <WrapItem>
  
    <Avatar  width={"30px"} height={"30px"} name='Squid Sad' src='https://scontent.fhan4-6.fna.fbcdn.net/v/t39.30808-6/428602281_904836657761100_9032628684338110125_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeGST2wRAyhUmUnmxaQvnFb8sVfzELIeWg-xV_MQsh5aD65OX26DqyETwBQX7a8Z8GJFwL5DZ6i5fEO0e5n7-aCA&_nc_ohc=qCsqjQqtA7sQ7kNvgHySgUT&_nc_ht=scontent.fhan4-6.fna&oh=00_AYBwbdAlB-IHOwCq0guOuv7kGv9FtM1ytUmE7C9FM0tZEQ&oe=66A677D2' />
  
  </WrapItem>

</Wrap>
                        </HStack>
                    </HStack>
                    <Flex alignItems={'center'}><Button variant={'solid'}
                        size={'sm'}
                        mr={4} onClick={toggleColorMode}>
                        {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                    </Button>
                        <Button
                            variant={'solid'}
                            colorScheme={'teal'}
                            size={'sm'}
                            mr={4}
                            leftIcon={<RepeatIcon />}
                            onClick={() => { dispatch({ type: "open" }) }}
                        >

                            Chạy
                        </Button>

                        <Button
                            variant={'solid'}
                            colorScheme={'teal'}
                            size={'sm'}
                            mr={4}
                            leftIcon={<LockIcon />}
                            onClick={() => {
                                dispatch({ type: "share", status: true })
                            }}>
                            {saveStatus}
                        </Button>
                        <Menu>
                            <MenuButton
                                as={Button}
                                rounded={'full'}
                                variant={'link'}
                                cursor={'pointer'}
                                minW={0}>
                                {state.user == null ? (<Avatar
                                    size='sm'
                                    src='https://banner2.cleanpng.com/20180703/ya/kisspng-computer-icons-user-avatar-user-5b3bafe2381423.1933594815306383062297.jpg'
                                />) : (<Avatar
                                    size='sm'
                                    src={state.user.photoURL}
                                />)}

                            </MenuButton>
                            <MenuList>
                                <MenuItem>
                                    {state.user === null && (<Button w={'full'} maxW={'md'} variant={'outline'} leftIcon={<FcGoogle />} onClick={signIn}>
                                        <Center>
                                            <Text>Sign in with Google</Text>
                                        </Center>
                                    </Button>)}
                                    {state.user != null && (<Button w={'full'} maxW={'md'} variant={'outline'} leftIcon={<FcKey />} onClick={handleLogout} >
                                        <Center>
                                            <Text>Đăng xuất</Text>
                                        </Center>
                                    </Button>)}

                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                            {Links.map((link) => (
                                <NavLink1 to={link} key={link}>{link}</NavLink1>
                            ))}
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </>
    )
}