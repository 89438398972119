// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDSrU_fz2j7r88zNd0fvpmi9kDbzPaTUVI",
  authDomain: "share-coding.firebaseapp.com",
  databaseURL: "https://share-coding-default-rtdb.firebaseio.com",
  projectId: "share-coding",
  storageBucket: "share-coding.appspot.com",
  messagingSenderId: "333435982563",
  appId: "1:333435982563:web:eb1f84aad38dffe0492bd6",
  measurementId: "G-1VF7R6ZZ3V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db=getFirestore(app)
export const auth = getAuth(app);