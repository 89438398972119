import React, { useEffect, useState } from "react";
import Navbar from "../navbar";
import { Box, IconButton } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db, auth } from '../../firebase';
import { useNavigate } from "react-router-dom";
import { useRunCode } from "../reducer/runCodeReducer";
import { useParams } from 'react-router-dom';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import ThreeTierPricing from "./price";

interface CodeData {
  // Define the structure of your data here
  // For example: auth: string, content: string, etc.
  auth: string,
  code: string,
  id: string,
  send_at: string,
  edit_at: string
}


function Premium() {
  const { id } = useParams();
  const Navigate = useNavigate();
  const [code, setCode] = useState<{ [key: string]: CodeData }>({})
  const { state, dispatch } = useRunCode();
  const [reload, setReload] = useState(false)
  // console.log(id);


  // if () {
  // Navigate("/Dashboard")
  // }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "pubCode"));
        const codeData: { [key: string]: CodeData } = {};
        querySnapshot.docs.forEach(doc => {
          const data = doc.data() as CodeData;
          codeData[doc.id] = { ...data, id: doc.id };
        });
        // if (codeData[key].auth == state.user.email) {
        const userAuthEmail = state.user.email;

        const filteredCodeData: { [key: string]: CodeData } = {};
        Object.keys(codeData).forEach(key => {
          if (codeData[key].auth === userAuthEmail) {
            filteredCodeData[key] = codeData[key];
          }
        });

        setCode(prevCode => ({ ...prevCode, ...filteredCodeData }));
        // }
        // Merge the fetched data with the existing state

      } catch (error) {
        console.error("Error fetching data from Firestore:", error);
      }
    };
    fetchData();
  }, [reload]);

  return (
    <>
      <Navbar />
      <ThreeTierPricing />
    </>
  );
}

export default Premium;
